// Assets
export const SET_ASSETS = 'SET_ASSETS';
export const SET_LAST_FETCH_ERROR = 'SET_LAST_FETCH_ERROR';
export const SET_FETCHING_ASSETS = 'SET_FETCHING_ASSETS';
export const SET_LAST_FETCH_START_TIME = 'SET_LAST_FETCH_START_TIME';
export const SET_UPDATING_OBSERVATION = 'SET_UPDATING_OBSERVATION';
export const SET_UPDATING_BULK_OBSERVATION = 'SET_UPDATING_BULK_OBSERVATION';
export const SET_ASSET_DETAILS_LAYOUT = 'SET_ASSET_DETAILS_LAYOUT';

// Layers
export const SET_LAYERS = 'SET_LAYERS';
export const SET_FETCHING_LAYERS = 'SET_FETCHING_LAYERS';

// Plans
export const SET_PLANS = 'SET_PLANS';
export const SET_FETCHING_PLANS = 'SET_FETCHING_PLANS';
export const SET_TWIN_PLAN_ID = 'SET_TWIN_PLAN_ID';

// Client cache
export const SET_CLIENT_CACHE = 'SET_CLIENT_CACHE';
export const REMOVE_CLIENT_CACHE = 'REMOVE_CLIENT_CACHE';

// Filters
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEARCH_TAGS = 'SET_SEARCH_TAGS';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const SELECT_ASSET_STATUS_FILTER = 'SELECT_ASSET_STATUS_FILTER';
export const SET_ASSET_LAYER_FILTER = 'SET_ASSET_LAYER_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const REMOVE_ASSET_STATUS_FILTER = 'REMOVE_ASSET_STATUS_FILTER';
export const REMOVE_ALL_ASSET_STATUS_FILTER = 'REMOVE_ALL_ASSET_STATUS_FILTER';
export const REMOVE_SELECTED_LAYER_IDS_FILTER = 'REMOVE_SELECTED_LAYER_IDS_FILTER';
export const SET_TABLE_FILTER = 'SET_TABLE_FILTER';
export const SET_TABLE_SORTER = 'SET_TABLE_SORTER';
export const SET_TABLE_PAGINATION = 'SET_TABLE_PAGINATION';
export const SET_LAYERS_FILTER = 'SET_LAYERS_FILTER';
export const SET_OPENED_LAYER_ID_FILTER = 'SET_OPENED_LAYER_ID_FILTER';
export const REMOVE_OPENED_LAYER_ID_FILTER = 'REMOVE_OPENED_LAYER_ID_FILTER';
export const SET_FILTERED_INFO = 'SET_FILTERED_INFO';
export const SET_INITIAL_FETCH_COMPLETE = 'SET_INITIAL_FETCH_COMPLETE';
export const SET_SUPPLIER_FILTER = 'SET_SUPPLIER_FILTER';
export const SET_MULTI_SEARCH_MODE_FILTER = 'SET_MULTI_SEARCH_MODE_FILTER';
export const SELECT_OBSERVATION_TYPE_FILTER = 'SELECT_OBSERVATION_TYPE_FILTER';
export const REMOVE_ALL_OBSERVATION_TYPE_FILTER = 'REMOVE_ALL_OBSERVATION_TYPE_FILTER';
export const SET_SHOW_ONLY_SELECTED = 'SET_SHOW_ONLY_SELECTED';
export const REMOVE_OBSERVATION_TYPE_FILTER = 'REMOVE_OBSERVATION_TYPE_FILTER';
export const SET_SHOW_SELECTED_ONLY = 'SET_SHOW_SELECTED_ONLY';
export const SET_SELECTED_ASSETS = 'SET_SELECTED_ASSETS';
export const RESET_SELECTED_ASSETS = 'RESET_SELECTED_ASSETS';
export const SET_COLLAPSABLE = 'SET_COLLAPSABLE';
export const SET_SELECTED_PLAN_BAND_IDS = 'SET_SELECTED_PLAN_BAND_IDS';
export const REMOVE_SELECTED_PLAN_BAND_ID = 'REMOVE_SELECTED_PLAN_BAND_ID';

// Files
export const SET_FILE_ERROR = 'SET_ERROR';
export const SET_IMG_SRC = 'SET_IMG_SRC';

// Menu
export const SET_MENU = 'SET_MENU';

// Access Rules
export const SET_ACCESS_RULES = 'SET_ACCESS_RULES';
export const SET_ACCESS_RULE = 'SET_ACCESS_RULE';
export const SET_FETCHING_ACCESS_RULES = 'SET_FETCHING_ACCESS_RULES';
export const SET_SEARCH_ACCESS_RULES = 'SET_SEARCH_ACCESS_RULES';

export const SET_CREATING_NEW_ACCESS_RULE_LOADING = 'SET_CREATING_NEW_ACCESS_RULE_LOADING';
export const SET_UPDATING_ACCESS_RULE_LOADING = 'SET_UPDATE_ACCESS_RULE_LOADING';
export const SET_ACCESS_RULES_IMPORT_CREATED_COUNT = 'SET_ACCESS_RULES_IMPORT_CREATED_COUNT';
export const SET_ACCESS_RULES_IMPORT_UPDATED_COUNT = 'SET_ACCESS_RULES_IMPORT_UPDATED_COUNT';

// Visibility Filter
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const SET_FILTERS_ACCESS_RULES_TABLE = 'SET_FILTERS_ACCESS_RULES_TABLE';
export const SET_FETCHING_VISIBILITY_FILTER = 'SET_FETCHING_VISIBILITY_FILTER';
export const SET_CREATING_VISIBILITY_FILTER_LOADING = 'SET_CREATING_VISIBILITY_FILTER_LOADING';
export const SET_UPDATING_VISIBILITY_FILTER_LOADING = 'SET_UPDATING_VISIBILITY_FILTER_LOADING';

// User
export const SET_FETCHING_USER = 'SET_FETCHING_USER';
export const SET_USER = 'SET_USER';

// Asset update tool
export const SET_ASSET_UPDATE_FILE = 'SET_ASSET_UPDATE_FILE';
export const SET_IS_APPLYING_UPDATE_ASSET = 'SET_IS_APPLYING_UPDATE_ASSET';
export const PLAN_UPDATE_ASSET = 'PLAN_UPDATE_ASSET';
export const SET_IS_PLANNING_UPDATE_ASSET = 'SET_IS_PLANNING_UPDATE_ASSET';
export const RESET_IMPORT_ASSET_UPDATE = 'RESET_IMPORT_ASSET_UPDATE';
export const SET_ASSET_UPDATE_FILTERS = 'SET_ASSET_UPDATE_FILTERS';
export const SET_TABLE_FILTERS = 'SET_TABLE_FILTERS';
export const SET_ERROR = 'SET_ERROR';
export const SET_SHOW_CHANGES_ONLY = 'SET_SHOW_CHANGES_ONLY';
export const SET_ASSET_UPDATE_MODE = 'SET_ASSET_UPDATE_MODE';

// Status update tool
export const SET_STATUS_UPDATE_FILE = 'SET_STATUS_UPDATE_FILE';
export const SET_IS_APPLYING_UPDATE_STATUSES = 'SET_IS_APPLYING_UPDATE_STATUSES';
export const PLAN_UPDATE_STATUSES = 'PLAN_UPDATE_STATUSES';
export const SET_IS_PLANNING_UPDATE_STATUSES = 'SET_IS_PLANNING_UPDATE_STATUSES';
export const RESET_IMPORT_STATUS_UPDATE = 'RESET_IMPORT_STATUS_UPDATE';
export const SET_STATUS_UPDATE_FILTERS = 'SET_STATUS_UPDATE_FILTERS';
export const SET_STATUSES_TABLE_FILTERS = 'SET_STATUSES_TABLE_FILTERS';
export const SET_STATUSES_ERROR = 'SET_STATUSES_ERROR';
export const SET_STATUSES_SHOW_CHANGES_ONLY = 'SET_STATUSES_SHOW_CHANGES_ONLY';
export const SET_STATUSES_UPDATE_MODE = 'SET_STATUSES_UPDATE_MODE';
export const RESET_ERROR = 'RESET_ERROR';

// Digital Twin
export const SET_IS_TWIN_READY = 'SET_IS_TWIN_READY';
export const SET_IS_TWIN_LOADING = 'SET_IS_TWIN_LOADING';
export const SET_IS_LOADING_PERCENTAGE = 'SET_IS_LOADING_PERCENTAGE';
export const SET_LAST_SELECTED_LAYER = 'SET_LAST_SELECTED_LAYER';
export const SET_MODELS = 'SET_MODELS';

// Time travel
export const SET_TIME_TRAVEL_DATE = 'SET_TIME_TRAVEL_DATE';

// Layout
export const SET_PAGE_LAYOUT = 'SET_PAGE_LAYOUT';
export const SET_DONT_SHOW_PRESENTER_SETTING = 'SET_DONT_SHOW_PRESENTER_SETTING';
export const SET_PRESENTER_MODE = 'SET_PRESENTER_MODE';
export const SET_PRESENTER_MODE_COMPONENTS = 'SET_PRESENTER_MODE_COMPONENTS';
export const SET_SUMMARIES_COLLAPSED = 'SET_SUMMARIES_COLLAPSED';
export const SET_ASSET_DETAILS_ID = 'SET_ASSET_DETAILS_ID';
// Devices
export const SET_FETCHING_DEVICES = 'SET_FETCHING_DEVICES';
export const SET_DEVICES = 'SET_DEVICES';

// Configuration
export const SET_CHANGES = 'SET_CHANGES';
export const SET_IS_SYNCING = 'SET_IS_SYNCING';
export const SET_BLUEPRINT_SYNC_PLAN = 'SET_BLUEPRINT_SYNC_PLAN';
export const SET_IS_CLONING = 'SET_IS_CLONING';
export const SET_IS_UPDATING = 'SET_IS_UPDATING';
export const RESET_CHANGES = 'RESET_CHANGES';
export const SET_BLUEPRINT_UPDATE = 'SET_BLUEPRINT_UPDATE';
export const SET_BLUEPRINT_SYNC = 'SET_BLUEPRINT_SYNC';

export const SET_IS_FETCHING_BLUEPRINT = 'SET_IS_FETCHING_BLUEPRINT';
export const RESET_BLUEPRINT = 'RESET_BLUEPRINT';
export const SET_CHANGES_BY_KEY = 'SET_CHANGE_BY_KEY';
export const RESET_ALL_CHANGES = 'RESET_ALL_CHANGES';
