import { createSelector } from 'reselect';
import {
  getAssetManagerFilters,
  getAssetManagerTwinPlanId,
  getAssetStatusesKeyedById,
  getAssetTypes,
  getCurrentPlanBandsForCharts,
  getPlansKeyedByTwinId,
  getSelectedAssetType,
  getSuppliersOptions,
} from '..';
import { getFilteredAssets, getFilteredAssetsContext, keyAssetsByBandId } from '.';
import { keyAssetsByStatus } from '../../utils';

const getFilteredAssetsForSupplierSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      openedLayerId,
      selectedBandIds,
      multiSearchModeFilter,
      selectedLayerIds,
    } = assetManagerFilters;
    if (
      !searchTags
      && !dateFilter?.length
      && !typeFilter
      && !selectedLayerIds
      && !assetStatusFilter?.ids?.length
      && !observationTypeFilter.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      selectedLayerIds,
      assetStatusFilter,
      selectedBandIds,
      observationTypeFilter,
      tableFilter,
      openedLayerId,
      multiSearchModeFilter,
    });
  },
);

export const getAssetsKeyedByStatusForSupplierSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForSupplierSummary],
  keyAssetsByStatus,
);

export const getAssetsKeyedByBandIdForSupplierSummary = createSelector(
  [getFilteredAssetsForSupplierSummary, getPlansKeyedByTwinId],
  keyAssetsByBandId,
);

export const getChartistDataForSupplierSummary = createSelector(
  [
    getAssetManagerTwinPlanId,
    getAssetStatusesKeyedById,
    getAssetsKeyedByStatusForSupplierSummary,
    getSelectedAssetType,
    getSuppliersOptions,
    getCurrentPlanBandsForCharts,
    getAssetsKeyedByBandIdForSupplierSummary,
  ],
  (
    twinPlanId,
    assetStatusesKeyedById,
    assetsKeyedByStatus,
    selectedAssetType,
    suppliers,
    currentPlanBands,
    assetsKeyedByBandId,
  ) => {
    const { statuses } = selectedAssetType;
    const getStatus = () => {
      const series = statuses?.map((status) => {
        const statusId = status.id;
        const assets = assetsKeyedByStatus.get(statusId);
        const statusLabel = assetStatusesKeyedById.get(statusId)?.label;
        let newSubSeries: Array<{ meta: string, value: number }> = [];

        newSubSeries = suppliers.map(({ value }) => {
          const count = assets?.filter(({ fields }) => {
            const { supplier } = fields || {};
            if (supplier === '' || supplier === undefined) {
              return value === '';
            }
            return value === `${supplier}`;
          })?.length || 0;
          return {
            meta: statusLabel!,
            value: count,
          };
        });
        return newSubSeries;
      });
      return series?.reverse() || [];
    };

    const getPlan = () => currentPlanBands.map((band) => {
      const assets = assetsKeyedByBandId.get(band.id) || [];
      const newSubSeries = suppliers?.map(({ value }) => {
        const count = assets.filter((asset) => {
          const { fields } = asset || {};
          return fields?.supplier === value;
        }).length;
        return {
          meta: band.label,
          value: count,
        };
      });

      return newSubSeries;
    }).reverse();
    const labels = suppliers.map(({ label }) => label) as Array<string>;
    const data = {
      labels,
      series: twinPlanId ? getPlan() : getStatus(),
    };

    return data;
  },
);
