import { Device } from '@ynomia/core/dist/device';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import some from 'lodash/some';
import {
  Asset,
  AssetStatusFilter,
  AssetTableColumn,
  RawObservationRecord,
  SYSTEM_TYPE,
} from '../../config/types';
import {
  checkIsFilteredByselectedBandIds,
  checkIsFilteredByDate,
  checkIsFilteredByLayer,
  checkIsFilteredByObservationType,
  checkIsFilteredByStatus,
  checkIsFilteredBySupplier,
  checkIsFilteredByTable,
  checkSearchFilter,
  getDataSourceAssetFromAsset,
  getObservationSameOrBeforeTimeTravelDate,
  getSelectedLayerAncestors,
} from '../../utils';
import {
  getAssetManagerAssetSelection,
  getAssetManagerFilters,
  getAssetManagerTwinPlanId,
  getAssetStatusesKeyedById,
  getAssetsArray,
  getBandsKeyedById,
  getCustomColumns,
  getDefaultAssetStatus,
  getLayersKeyedByAncestorId,
  getLinkedDevicesKeyedByAssetId,
  getListViewColumns,
  getPlansKeyedByTwinId,
} from '..';
import { AssetFilter } from '../../context';
import { assetFilterIsActive } from '../../utils/assetFilters';
import map from '../map';

const getTimeTravelAssetsArray = createSelector(
  [
    getAssetManagerFilters,
    getAssetsArray,
    getDefaultAssetStatus,
  ],
  (
    assetManagerFilters,
    assetsArray,
    defaultStatus,
  ) => {
    const { timeTravelDate } = assetManagerFilters;
    if (!timeTravelDate) return assetsArray;
    // If the default status (usually "Added to Ynomia") is in the filter, allow all assets
    return assetsArray.map((asset: Asset) => {
      const newAsset = { ...asset } as Asset;
      const filteredObservations: RawObservationRecord[] = getObservationSameOrBeforeTimeTravelDate(
        asset,
        timeTravelDate,
      );

      const filteredObservationsStatusUpdate = filteredObservations.filter(
        ({ type }) => type === SYSTEM_TYPE.AUTOMATIC_STATUS_UPDATE_OBSERVATION
          || type === SYSTEM_TYPE.STATUS_UPDATE_OBSERVATION,
      );

      newAsset.observations = filteredObservations;

      if (filteredObservationsStatusUpdate[0]?.to) {
        const lastStatusUpdate = filteredObservationsStatusUpdate.find(
          ({ isOutOfSequence }) => !isOutOfSequence,
        ) || filteredObservationsStatusUpdate[0];
        newAsset.status = lastStatusUpdate.to;

        newAsset.statusUpdatedDate = dayjs(lastStatusUpdate.date).toDate();
      } else {
        newAsset.status = defaultStatus?.id;
        newAsset.statusUpdatedDate = dayjs(asset.created).toDate();
      }

      return newAsset;
    });
  },
);

export const getTimeTravelAssetsById = createSelector(
  [getTimeTravelAssetsArray],
  timeTravelAssetsArray => new Map(
    timeTravelAssetsArray.map(asset => [asset.id, asset]),
  ) as Map<string, Asset>,
);

export const getTimeTravelAssetsByTwinId = createSelector(
  [getTimeTravelAssetsById],
  (timeTravelAssetsById) => {
    const assetArray = [...timeTravelAssetsById.values()];
    return new Map(assetArray.map((asset) => {
      const [twinId] = asset.destination?.twinID || '';
      return [twinId, asset];
    })) as Map<string, Asset>;
  },
);

const getObservationTypesKeyedByAssetId = createSelector(
  [getTimeTravelAssetsArray],
  assetsArray => new Map(
    assetsArray.map(({ id, observations }) => [id, observations?.map(({ type }) => type)]),
  ),
);

export const getShowOnlySelectedAssets = createSelector(
  [
    getAssetManagerAssetSelection,
    getTimeTravelAssetsById,
  ],
  (assetManagerAssetSelection, assetsKeyedById) => {
    const { selectedAssets } = assetManagerAssetSelection;
    return selectedAssets.map(selected => assetsKeyedById.get(selected)) as Array<Asset>;
  },
);

export const getTimeTravelAssetsKeyedByTypes = createSelector(
  [map.assetTypes, getTimeTravelAssetsArray],
  (assetTypes, assetArray) => {
    const assetsKeyedByTypes = new Map<string, Array<Asset>>();
    if (assetTypes && assetArray) {
      assetTypes.forEach(({ id }) => {
        const assetsFilteredByStatusId = assetArray.filter(({ type }) => type === id);
        assetsKeyedByTypes.set(id, assetsFilteredByStatusId);
      });
    }

    return assetsKeyedByTypes as Map<string, Array<Asset>>;
  },
);

export const getAssetsArrayFromAssetType = createSelector(
  [getAssetManagerFilters, getTimeTravelAssetsKeyedByTypes, getTimeTravelAssetsArray],
  (assetManagerFilters, assetsKeyedByTypes, assetsArray) => {
    const { typeFilter } = assetManagerFilters;
    return assetsKeyedByTypes.get(typeFilter) || assetsArray;
  },
);

/**
 * getDataSourceKeyedByAssetId performs some heavy operations per asset,
 * hence why we cache the computed result for each asset.
 * `customColumnsRef` and `linkedDevicesKeyedByAssetIdRef` are used as part of
 * a custom selector which helps us determine when to recalculate all the data.
 */
const dataSourceAssetCache = new Map<Asset, any>();
let customColumnsRef: AssetTableColumn[];
let timeTravelAssetsArrayRef: Array<Asset>;
let linkedDevicesKeyedByAssetIdRef: Map<string | undefined, Device>;
export const getDataSourceKeyedByAssetId = createSelector(
  [
    map.layersKeyedById,
    getTimeTravelAssetsById,
    getCustomColumns,
    getAssetsArrayFromAssetType,
    getAssetStatusesKeyedById,
    getLinkedDevicesKeyedByAssetId,
    getTimeTravelAssetsArray,
    getPlansKeyedByTwinId,
    getBandsKeyedById,
  ],
  (
    layersKeyedById,
    assetsKeyedById,
    customColumns,
    assetsArray,
    assetStatusesKeyedById,
    linkedDevicesKeyedByAssetId,
    timeTravelAssetsArray,
    plansKeyedByTwinId,
    bandsKeyedById,
  ): Map<string, ReturnType<typeof getDataSourceAssetFromAsset>> => {
    /**
     * Recompute all assets when custom columns or linked devices change.
     */
    if (
      customColumns !== customColumnsRef
      || (timeTravelAssetsArray !== timeTravelAssetsArrayRef)) {
      dataSourceAssetCache.clear();
      customColumnsRef = customColumns;
      timeTravelAssetsArrayRef = timeTravelAssetsArray;
    }

    if (linkedDevicesKeyedByAssetId !== linkedDevicesKeyedByAssetIdRef) {
      dataSourceAssetCache.clear();
      linkedDevicesKeyedByAssetIdRef = linkedDevicesKeyedByAssetId;
    }

    return new Map(assetsArray.map(
      (asset: Asset) => {
        const cacheHit = dataSourceAssetCache.get(asset);
        if (cacheHit) return [asset.id, cacheHit];
        const data = getDataSourceAssetFromAsset(
          asset,
          customColumns,
          layersKeyedById,
          assetsKeyedById,
          assetStatusesKeyedById,
          linkedDevicesKeyedByAssetId,
          plansKeyedByTwinId,
          bandsKeyedById,
        );

        dataSourceAssetCache.set(asset, data);
        return [asset.id, data];
      },
    ));
  },
);

export const getFilteredAssetsContext = createSelector(
  [
    map.layersKeyedById,
    getLinkedDevicesKeyedByAssetId,
    getTimeTravelAssetsArray,
    getAssetStatusesKeyedById,
    getListViewColumns,
    getLayersKeyedByAncestorId,
    getObservationTypesKeyedByAssetId,
    getDataSourceKeyedByAssetId,
    getPlansKeyedByTwinId,
    getAssetManagerTwinPlanId,
  ],
  (
    layersKeyedById,
    linkedDevicesKeyedByAssetId,
    assetsArray,
    assetStatusesKeyedById,
    listViewColumns,
    layersKeyedByAncestorId,
    observationTypesKeyedByAssetId,
    listViewDataSource,
    plansKeyedByTwinId,
    twinPlanId,
  ) => ({
    layersKeyedById,
    linkedDevicesKeyedByAssetId,
    assetsArray,
    assetStatusesKeyedById,
    listViewColumns,
    layersKeyedByAncestorId,
    observationTypesKeyedByAssetId,
    listViewDataSource,
    plansKeyedByTwinId,
    twinPlanId,
  }),
);

export const getFilteredAssets = (
  {
    layersKeyedById,
    linkedDevicesKeyedByAssetId,
    assetsArray,
    assetStatusesKeyedById,
    listViewColumns,
    layersKeyedByAncestorId,
    observationTypesKeyedByAssetId,
    listViewDataSource,
    plansKeyedByTwinId,
    twinPlanId,
  }: ReturnType<typeof getFilteredAssetsContext>,
  {
    searchTags,
    searchText,
    dateFilter,
    typeFilter,
    assetStatusFilter,
    observationTypeFilter = {},
    tableFilter,
    selectedBandIds,
    openedLayerId,
    supplierFilter,
    multiSearchModeFilter,
    selectedLayerIds,
  }: Partial<AssetFilter>,
): Array<Asset> => {
  const defaultStatus = [...assetStatusesKeyedById.values()].find(s => s.default);
  const observationTypeFilterIds = Object.keys(observationTypeFilter).length || 0;

  const filteredAssets = assetsArray
    .filter((asset: Asset) => {
      const { destination } = asset;
      const layerID = destination?.layerID as string;
      const dataSourceAsset = listViewDataSource.get(asset.id) || {};

      const isFilteredBySearch = () => (!searchTags?.length && !searchText)
        || checkSearchFilter(
          searchTags || [],
          searchText,
          multiSearchModeFilter,
          dataSourceAsset,
          twinPlanId,
        );
      const isFilteredByType = () => !typeFilter || typeFilter === asset.type;
      const isFilteredByDate = () => !(dateFilter?.length === 2)
        || checkIsFilteredByDate(asset, dateFilter, assetStatusFilter);
      const isFilteredByStatus = () => !assetStatusFilter?.ids.length || checkIsFilteredByStatus(
        asset,
        assetStatusesKeyedById,
        assetStatusFilter as AssetStatusFilter,
        defaultStatus,
      );
      const selectedLayerAncestors = getSelectedLayerAncestors(
        selectedLayerIds || [],
        layersKeyedByAncestorId,
      );
      const isFilteredByLevel = () => !selectedLayerAncestors?.length
        || selectedLayerAncestors.includes(layerID);
      const isFilteredByLayer = () => (!openedLayerId)
        || checkIsFilteredByLayer(
          openedLayerId,
          layersKeyedById,
          layerID,
          layersKeyedByAncestorId,
        );

      const isTableFilterExist = () => some(tableFilter, value => !!value?.length);
      const isFilteredByObservationType = () => !observationTypeFilterIds
        || checkIsFilteredByObservationType(
          asset,
          observationTypeFilter,
          observationTypesKeyedByAssetId,
        );

      const isFilteredByTable = () => !isTableFilterExist
        || checkIsFilteredByTable(
          asset,
          linkedDevicesKeyedByAssetId,
          tableFilter,
          listViewColumns,
          dataSourceAsset,
        );

      const isFilteredByPlan = () => !selectedBandIds?.length
      || checkIsFilteredByselectedBandIds(
        asset,
        plansKeyedByTwinId,
        selectedBandIds,
      );

      const isFilteredBySupplier = () => (
        supplierFilter?.length === 0 || supplierFilter === undefined
      ) || checkIsFilteredBySupplier(asset, supplierFilter);

      return isFilteredBySearch()
        && isFilteredByDate()
        && isFilteredByType()
        && isFilteredByStatus()
        && isFilteredByLevel()
        && isFilteredByLayer()
        && isFilteredByTable()
        && isFilteredBySupplier()
        && isFilteredByPlan()
        && isFilteredByObservationType();
    });
  return filteredAssets;
};

export const getActiveAssetFilters = createSelector(
  [getAssetManagerFilters],
  assetFilterIsActive,
);
