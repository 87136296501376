import {
  AssetType, ObservationSchema,
} from '@ynomia/core/dist/blueprint';
import { createSelector } from 'reselect';
import {
  getAssetManagerFilters,
  getAssetTypes,
  getAssetsArray,
} from '.';

import { DEFAULT_STATUS_COLOR } from '../config/constants';
import map from './map';

export const getSelectedAssetType = createSelector(
  [getAssetManagerFilters, getAssetTypes],
  (assetManagerFilters, assetTypes) => {
    const { typeFilter } = assetManagerFilters;
    return (assetTypes.find(({ type }) => type === typeFilter) || assetTypes[0]) as AssetType;
  },
);

export const getSuppliersOptions = createSelector(
  [
    map.presenterMode,
    getAssetManagerFilters,
    getAssetsArray,
    getSelectedAssetType,
  ],
  (
    presenterMode,
    assetManagerFilters,
    assetsArray,
    selectedAssetType,
  ) => {
    const fields = selectedAssetType?.fields || [];
    const { supplierFilter } = assetManagerFilters;
    let suppliersOptions: Array<{
      label: string,
      value: string,
    }> = [];

    const supplierListFromAsset = new Map(
      assetsArray?.map(asset => [`${asset.fields?.supplier || ''}`, asset]),
    );

    const supplierField = fields?.find(field => field.id === 'supplier');
    if (supplierField) {
      suppliersOptions = supplierField?.properties?.options as Array<{
        label: string,
        value: string,
      }> || [];
    }

    const hasAssetWithoutSupplier = supplierListFromAsset.get('');
    if (hasAssetWithoutSupplier) {
      const blankOption = {
        label: !suppliersOptions.length && selectedAssetType
          ? selectedAssetType.name : '(blank)',
        value: '',
      };
      suppliersOptions = [...suppliersOptions, blankOption];
    }

    if (presenterMode && supplierFilter.length) {
      suppliersOptions = suppliersOptions.filter(
        ({ label }) => supplierFilter.includes(label),
      );
    }

    return suppliersOptions;
  },
);

export const getObservationTypes = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    return observationTypes;
  },
);

export const getObservationTypesKeyedById = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    const observationTypesKeyedById = new Map();

    observationTypes.forEach((type) => {
      observationTypesKeyedById.set(type.id, type);
    });

    return observationTypesKeyedById as Map<string, ObservationSchema>;
  },
);

export const getSelectedAssetTypeStatuses = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const statuses = selectedAssetType?.statuses || [];
    return statuses;
  },
);

export const getColorSchemeForStatus = createSelector(
  [getSelectedAssetType],
  (selectedAssetType): Record<string, string> => {
    const assetStatuses = selectedAssetType?.statuses || [];
    return assetStatuses.reduce((acc, { label, color }) => ({
      ...acc,
      [label]: color || DEFAULT_STATUS_COLOR,
    }), {});
  },
);
