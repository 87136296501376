import { AssetStatus, PlannedTwinID, SiteTemplate } from '@ynomia/core/dist/blueprint';
import dayjs, { Dayjs } from 'dayjs';
import { FilterValue } from 'antd/es/table/interface';
import { escapeRegexStr } from '@ynomia/core/dist/utils';
import { omit } from 'lodash';
import {
  AccessRule,
  Asset,
  AssetStatusFilter,
  AssetTableColumn,
  Layer,
  RawObservationRecord,
  ToggleObservation,
} from '../config/types';
import { isFilteredByDateFilter } from '.';
import { CUSTOM_DATE_COLUMNS } from '../config/constants';

export const checkSearchFilter = (
  searchTags: Array<string>,
  searchText: string | undefined,
  multiSearchModeFilter: 'or' | 'and' | undefined,
  assetToTest: Record<string, any>,
  twinPlanId: string | undefined | null,
): boolean => {
  // Only search plan related fields when the user's viewing a plan
  const asset = !twinPlanId
    ? omit(assetToTest, ['plannedDueDate', 'planStatus', 'plannedStatus'])
    : assetToTest;

  const assetValues = Object.values(asset).filter(value => typeof value === 'string');
  const assertValueString = assetValues.join(' ');
  const searchTextTags = searchText ? [searchText, ...searchTags] : searchTags;

  if (searchTextTags.length <= 1) {
    return !!assertValueString.match(
      new RegExp(
        escapeRegexStr(searchTextTags.join()),
        'gi',
      ),
    );
  } if (multiSearchModeFilter === 'and') {
    return searchTextTags.every(q => assertValueString.toLowerCase().includes(q.toLowerCase()));
  }
  return !!assertValueString?.match(
    new RegExp(
      searchTextTags.map(text => text.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')).join('|'),
      'gi',
    ),
  );
};

export const checkIsFilteredByDate = (
  asset: Asset,
  dateFilter: [Dayjs, Dayjs] | [],
  assetStatusFilter: AssetStatusFilter | undefined,
): boolean => {
  const { observations } = asset;

  if (!observations?.length && dateFilter.length === 2) return false;
  if (dateFilter.length !== 2) return true;
  if (!observations) return false;

  if (assetStatusFilter?.ids?.length) {
    if (assetStatusFilter.toggle === 'cumulative') {
      return observations
        .filter(({ to }) => to && assetStatusFilter.ids.includes(to))
        .some(({ date }) => {
          const dateFormat = date && new Date(date);
          return !!dateFormat
            && isFilteredByDateFilter(dateFormat, dateFilter);
        });
    }
    /**
     * Code reaches here for the list view current column
     * */
    return assetStatusFilter.ids.some((filterStatus) => {
      const firstObservationWithStatus = observations.find(({ to }) => to && filterStatus === to);
      const obsDate = firstObservationWithStatus?.date;
      const dateFormat = obsDate && new Date(obsDate);

      return !!dateFormat
        && isFilteredByDateFilter(dateFormat, dateFilter)
        && !!firstObservationWithStatus.isCurrentStatus;
    });
  }
  /**
     * Code reaches here for the status summary with a date filter.
     * We want to show assets that have any observation in the date range.
     * */
  return observations.some(({ date }) => {
    const dateFormat = date && new Date(date);
    return dateFormat && isFilteredByDateFilter(dateFormat, dateFilter);
  });
};

export const checkIsFilteredByStatus = (
  asset: Asset,
  assetStatusesKeyedById: Map<string, AssetStatus>,
  assetStatusFilter: AssetStatusFilter,
  defaultStatus?: AssetStatus,
): boolean => {
  const { status, observations } = asset;
  const { ids, toggle } = assetStatusFilter || {};
  const assetStatus = status && (assetStatusesKeyedById.get(status) as AssetStatus);

  if (status && ids.includes(status)) {
    return true;
  }
  if (assetStatus && (toggle === 'cumulative')) {
    if (ids.includes(defaultStatus?.id || '')) return true;
    return !!observations?.find((observation) => {
      if (observation && observation?.to) {
        return assetStatusFilter.ids.includes(observation?.to);
      }
      return false;
    });
  }
  return false;
};

export const checkIsFilteredByselectedBandIds = (
  asset: Asset,
  plansKeyedByTwinId: Map<string, PlannedTwinID>,
  selectedBandIds: Array<string>,
): boolean => {
  const [twinID] = asset?.destination?.twinID || [];
  const currentPlanId = plansKeyedByTwinId.get(twinID) || null;

  if (selectedBandIds.includes('unknown') && currentPlanId === null) {
    return true;
  }

  const currentBand = currentPlanId?.result?.bandID || '';
  return selectedBandIds.includes(currentBand);
};

export const checkIsFilteredByObservationType = (
  asset: Asset,
  observationTypeFilter: Record<string, ToggleObservation>,
  observationTypesKeyedByAssetId: Map<string, Array<string> | undefined>,
): boolean => {
  const { id } = asset;
  const observationTypesFromAsset = observationTypesKeyedByAssetId.get(id) || [];

  const observationTypeFilterResult: Array<boolean> = [];

  Object.keys(observationTypeFilter)?.forEach((key) => {
    const value = observationTypeFilter[key];
    if (value === 'with') {
      observationTypeFilterResult.push(observationTypesFromAsset.includes(key));
    } else {
      observationTypeFilterResult.push(!observationTypesFromAsset.includes(key));
    }
  });

  return !observationTypeFilterResult.includes(false);
};

export const checkIsFilteredByTable = (
  asset: Asset,
  linkedDevicesKeyedByAssetId,
  tableFilter: Record<string, FilterValue | null> | undefined,
  listViewColumns: Array<AssetTableColumn>,
  assetToTest: Record<string, any>,
): boolean => {
  const isTableFilterIsEmpty = tableFilter && Object.values(tableFilter).every(x => x === null);

  // True is to show the asset
  let isFilteredByTable = true;

  if (!tableFilter || isTableFilterIsEmpty) {
    return true;
  }

  const tableFilterKeys: Array<string> = [];
  Object.keys(tableFilter).forEach((key) => {
    const valueTableFilter = tableFilter[key];
    if (valueTableFilter) {
      tableFilterKeys.push(key);
    }
  });

  tableFilterKeys.forEach((key) => {
    const listViewColumn = listViewColumns.find(col => col.key === key);
    let res = true; // Always gets overwritten
    // Set res to true if we want to show it
    if (tableFilter[key] && CUSTOM_DATE_COLUMNS.includes(listViewColumn?.custom || '')) {
      const [tableFilterValue] = tableFilter[key] as FilterValue;
      const [start, end] = (tableFilterValue as string).split(',');
      res = isFilteredByDateFilter(
        new Date(assetToTest[key]),
        [dayjs(start), dayjs(end)],
      );
    } else if (tableFilter[key] && listViewColumn?.custom === 'tagLink') {
      const device = !!linkedDevicesKeyedByAssetId.get(asset.id);
      res = !!tableFilter[key]?.includes(device);
    } else {
      res = !!tableFilter[key]?.includes(assetToTest[key] || '');
    }

    isFilteredByTable = isFilteredByTable && res;
  });

  return isFilteredByTable;
};

export const checkIsFilteredByLayer = (
  openedLayerId: string | null | undefined,
  layersKeyedById: Map<string, Layer>,
  layerID: string | null,
  layerIdsKeyedByAncestorId: Map<string, Array<Layer>>,
): boolean => {
  const assetLayer = layersKeyedById.get(layerID!);
  const { parentID, isRoot } = assetLayer || {};

  if (isRoot) {
    return false;
  } if (!parentID) {
    return false;
  }
  return !!layerIdsKeyedByAncestorId.get(openedLayerId!)!?.find(l => l.id === layerID);
};

export const checkSearchAccessRulesFilter = (
  accessRule: AccessRule,
  searchAccessRules: string | null,
): boolean => {
  const {
    notes, createdAt, roles, filters, name,
  } = accessRule || {};
  const { email } = accessRule?.user || {};

  if (searchAccessRules === null) return true;

  const userValues = [...Object.values({
    email,
    name,
    notes,
    roles,
    filters,
  }), dayjs(createdAt).format('MMM DD YYYY hh:mma')];

  const filterRegex = new RegExp(escapeRegexStr(searchAccessRules?.trim()), 'gi');
  return !!JSON.stringify(userValues)?.match(filterRegex);
};

export const checkAccessRulesFilteredByTableFilter = (
  accessRule: AccessRule,
  filtersAccessRulesTable: Record<string, FilterValue | null>,
): boolean => {
  const filterAccessRules = {};
  const filtersAccessRulesTableKeys = Object.keys(filtersAccessRulesTable);

  if (!filtersAccessRulesTableKeys.length) return true;

  filtersAccessRulesTableKeys.forEach((key) => {
    if (filtersAccessRulesTable[key]) {
      filterAccessRules[key] = filtersAccessRulesTable[key];
    }
  });

  const filterKeys = Object.keys(filterAccessRules);
  const flattenAccessRule = { ...accessRule, ...accessRule.user };
  const isFiltered = filterKeys.every(
    key => filterAccessRules[key]?.includes(flattenAccessRule[key]),
  );

  return isFiltered;
};

export const checkIsFilteredBySupplier = (
  asset: Asset,
  supplierFilter: Array<string>,
): boolean => {
  const { fields } = asset;
  const { supplier } = fields || {};
  const isSupplierEmpty = supplier === '' || supplier === undefined;

  return isSupplierEmpty ? supplierFilter.includes('(blank)') : supplierFilter.includes(supplier);
};

export const getObservationSameOrBeforeTimeTravelDate = (
  asset: Asset,
  timeTravelDate: Dayjs,
): Array<RawObservationRecord> => {
  if (!asset) return [];
  const timeTravelDateMs = timeTravelDate.toDate().getTime();
  const { observations } = asset;
  if (!observations) return [];

  const filteredObservation = observations.filter(
    ({ date, created }) => timeTravelDateMs >= new Date(date || created).getTime(),
  );

  return filteredObservation;
};

export const getChildLayerFilter = (
  openedLayerId: string | null,
  buildingTemplates: SiteTemplate | undefined,
  layersKeyedById: Map<string, Layer>,
): Array<Layer> => {
  const length = buildingTemplates?.primary_layer_path?.length || 0;
  if (!buildingTemplates || length < 2 || !openedLayerId) {
    return [];
  }
  const getLayerRecursive = (layer?: Layer) => {
    const parentLayer = layersKeyedById.get(layer?.parentID!)!;
    if (!layer) {
      return [];
    }
    if (!parentLayer || parentLayer.isRoot) {
      return [layer];
    }
    return [...getLayerRecursive(parentLayer), layer];
  };

  const res = getLayerRecursive(layersKeyedById.get(openedLayerId));
  return res;
};

export const getNextStatusDefault = (
  observations: RawObservationRecord[] | undefined,
  statusesFromSelectedType: AssetStatus[],
  statusesOptions: {
    label: string;
    value: string;
    statusDefault: boolean | undefined;
    order: number | undefined;
  }[],
) => {
  const orderedObservations = observations?.filter(({ to }) => to).sort(
    (a, b) => -(a.date || '').localeCompare((b.date || '')),
  );
  const statusesFromSelectedTypeIds = statusesFromSelectedType.map(({ id }) => id);

  const lastStatus = orderedObservations?.find(
    ({ to, isOutOfSequence }) => to
      && statusesFromSelectedTypeIds.includes(to)
      && !isOutOfSequence,
  );

  const lastStatusOrder = lastStatus?.order;

  if (lastStatusOrder && lastStatusOrder + 1 === statusesOptions.length) {
    return statusesOptions[statusesOptions.length - 1];
  }
  if (lastStatusOrder && lastStatusOrder >= 1) {
    return statusesOptions.find(({ order }) => lastStatusOrder + 1 === order);
  }
  return statusesOptions.find(({ statusDefault }) => statusDefault);
};
